// EMBED
(() => {
  // Datawrapper responsive scaling
  // https://academy.datawrapper.de/article/180-how-to-embed-charts
  if (window && window.addEventListener) {
    window.addEventListener('message', function _message(a) {
      if (a.data['datawrapper-height'] !== undefined) {
        for (let e in a.data['datawrapper-height']) {
          const t =
            document.getElementById('datawrapper-chart-' + e) ||
            document.querySelector("iframe[src*='" + e + "']");
          if (t) {
            t.style.height = a.data['datawrapper-height'][e] + 'px';
          }
        }
      }
    });
  }

  // handle datawrapper darkmode
  const darkmode =
    window &&
    window.tv2 &&
    window.tv2.utils &&
    window.tv2.utils.getPreferredTheme() === 'dark';
  if (darkmode) {
    const datawrapperEmbeds = document.querySelectorAll(
      '[data-embed-vendor="datawrapper"]',
    );
    if (datawrapperEmbeds) {
      // create timestamp rounded to nearest minute
      const coeff = 1000 * 60;
      const timestamp = Date.parse(new Date(Math.round(new Date / coeff) * coeff))/1000;

      datawrapperEmbeds.forEach((embed) => {
        const str = '?dark=';
        const param = `${str}${timestamp}`;
        const iframe = embed.querySelector('iframe[src*="datawrapper"]');
        if (!iframe.src.includes(str)) {
          iframe.src = `${iframe.src}${param}`;
        }
      });
    }
  }
})();
